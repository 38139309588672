import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Form, FormGroup, FormControl, FormLabel, Button, Container } from 'react-bootstrap';

const AddUserForm = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        const fetchCompanies = async () => {
            const companiesCollection = collection(db, "companies");
            const companiesSnapshot = await getDocs(companiesCollection);
            const companiesList = companiesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCompanies(companiesList);
        };

        fetchCompanies();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, "temporaryPassword");
            const user = userCredential.user;

            await sendPasswordResetEmail(auth, email);

            await addDoc(collection(db, "users"), {
                companyId: selectedCompanyId,
                firstName,
                lastName,
                uid: user.uid,
            });

            setSelectedCompanyId("");
            setFirstName("");
            setLastName("");
            setEmail("");

            alert("User added successfully!");
        } catch (error) {
            console.error("Error adding user: ", error);
            alert(error.message);
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                    <FormLabel>Select Company</FormLabel>
                    <FormControl
                        as="select"
                        value={selectedCompanyId}
                        onChange={(e) => setSelectedCompanyId(e.target.value)}
                        required
                    >
                        <option value="">Select a company</option>
                        {companies.map((company) => (
                            <option key={company.id} value={company.id}>
                                {company.companyName}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel>First Name</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel>Last Name</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>
                <Button variant="primary" type="submit">
                    Add User
                </Button>
            </Form>
        </Container>
    );
};

export default AddUserForm;