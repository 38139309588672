import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth"
import { getFirestore } from 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyCWcOTEpKTId3r8oUE1fPW87mLmwJ5RLpw",
  authDomain: "rail-4e61a.firebaseapp.com",
  databaseURL: "https://rail-4e61a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rail-4e61a",
  storageBucket: "rail-4e61a.appspot.com",
  messagingSenderId: "982254536867",
  appId: "1:982254536867:web:91def29f60488bdac8d716",
  measurementId: "G-QRG0H4KFQ8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const database = getDatabase(app);

export { auth, db, database };