import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../Firebase';
import { Container, Row, Col, Card, Spinner, ListGroup } from 'react-bootstrap';

function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [allocatedTrains, setAllocatedTrains] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoading(true);

        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userCompanyId = userDoc.data().companyId;
        setCompanyId(userCompanyId);

        const q = query(
          collection(db, "orders"),
          where("transportCompany", "==", companyId),
          where("trainId", "==", null)
        );
        const querySnapshot = await getDocs(q);
        const unassignedOrders = querySnapshot.docs.map((doc) => doc.data());
        setOrders(unassignedOrders);

        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        const trainsQuery = query(
          collection(db, "allocatedTrains"),
          where("departureDate", ">=", today),
          where("departureDate", "<=", tomorrow)
        );
        const trainsSnapshot = await getDocs(trainsQuery);
        const allocatedTrainsData = trainsSnapshot.docs.map((doc) => doc.data());
        setAllocatedTrains(allocatedTrainsData);

        setIsLoading(false);
      }
    });

    return () => authListener();
  }, [companyId]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Unassigned Orders</h1>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Card>
              <Card.Body>
                <ListGroup variant="flush">
                  {orders.length > 0 ? (
                    orders.map((order) => (
                      <ListGroup.Item key={order.id}>
                        <Row>
                          <Col sm={4}>{order.from} - {order.to}</Col>
                          <Col sm={4}>{order.goodsType}</Col>
                          <Col sm={4}>{order.arrival}</Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <p>No unassigned orders at the moment.</p>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col>
          <h1>Running Trains</h1>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Card>
              <Card.Body>
                <ListGroup variant="flush">
                  {allocatedTrains.length > 0 ? (
                    allocatedTrains.map((train) => (
                      <ListGroup.Item key={train.id}>
                        <Row>
                          <Col sm={4}>{train.departureStation} - {train.arrivalStation}</Col>
                          <Col sm={4}>{train.trainType}</Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <p>No running trains at the moment.</p>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;