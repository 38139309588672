import { auth } from './Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Comp/Dashboard';
import AddUser from './Comp/AddUser';
import Login from './Comp/Login';
import Navigation from './Comp/Navigation';
import OrderForm from './Comp/OrderForm';
// import UpdateSchedules from './Comp/UpdateSchedules'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Router>
        <Navigation isLoggedIn={isLoggedIn} />
        {isLoggedIn ? (
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/adduser" element={<AddUser user={auth} />} />
            <Route path="/neworder" element={<OrderForm />} />
            {/* <Route path="/updateschedules" element={<UpdateSchedules />} /> */}
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        )}
      </Router>
    </>
  );
}

export default App;
library.add(fas)