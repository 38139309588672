import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { request, gql } from 'graphql-request';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OrderForm = () => {
  const [companyNames, setCompanyNames] = useState([]);
  const [transportCompany, setTransportCompany] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [stationOptions, setStationOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    companyId: '',
    from: '',
    to: '',
    departure: '',
    arrival: '',
    goodsType: '',
    orderedBy: '',
    trainId: null
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        setUserId(user.uid);
        if (!user) {
          throw new Error("User not authenticated");
        }

        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (!userDoc.exists()) {
          throw new Error("User data not found");
        }
        const { companyId } = userDoc.data();
        setTransportCompany(companyId);

        const q = query(collection(db, "clients"), where("companyId", "==", companyId));
        const querySnapshot = await getDocs(q);

        const companyList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          companyName: doc.data().companyName,
        }));

        setCompanyNames(companyList);
      } catch (error) {
        console.error("Error fetching companies: ", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchStations = async () => {
      const endpoint = 'https://rata.digitraffic.fi/api/v2/graphql/graphql';
      const query = gql`
          query {
            stations {
              shortCode
              name
            }
          }
        `;
      try {
        const data = await request(endpoint, query);
        setStationOptions(data.stations.map(station => ({
          label: `${station.name} (${station.shortCode})`,
          shortCode: station.shortCode,
        })));
      } catch (error) {
        console.error("Error fetching stations:", error);
      }
    };

    fetchCompanies();
    fetchStations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'isDangerousGoods') {
      setFormValues({
        ...formValues,
        [name]: e.target.checked, // Set isDangerousGoods to the checkbox's checked state
        unNumber: e.target.checked ? '' : formValues.unNumber, // Clear UN number if unchecked
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleStationSelect = (selected, field) => {
    if (selected.length > 0) {
      setFormValues({
        ...formValues,
        [field]: selected[0].shortCode,  // Store shortCode of selected station
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "orders"), {
        trainId: null,
        ...formValues,
        transportCompany,
        orderedBy: userId,
        createdAt: new Date(),
      });
      alert("Order submitted successfully!");
      setFormValues({
        companyId: '',
        transportCompany: transportCompany,
        from: '',
        to: '',
        departure: '',
        arrival: '',
        goodsType: '',
        isDangerousGoods: false,
        unNumber: '',
      });
    } catch (error) {
      console.error("Error submitting order: ", error);
    }
  };

  const handleClearStation = (field) => {
    setFormValues({
      ...formValues,
      [field]: '',
    });
  };

  return (
    <Container>
      <h2 className="my-4">Place a New Order</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="company-select">
          <Form.Label column sm={2}>Select Company:</Form.Label>
          <Col sm={10}>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Form.Control
                as="select"
                name="companyId"
                value={formValues.companyId}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a company...</option>
                {companyNames.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.companyName}
                  </option>
                ))}
              </Form.Control>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="from">
          <Form.Label column sm={2}>From:</Form.Label>
          <Col sm={9}>
            <Typeahead
              id="from-station"
              labelKey="label"
              options={stationOptions}
              onChange={(selected) => handleStationSelect(selected, 'from')}
              placeholder="Starting station"
              selected={stationOptions.filter(option => option.shortCode === formValues.from)}
            />
          </Col>
          <Col sm={1}>
            <Button variant="secondary" size="sm" onClick={() => handleClearStation('from')}>
              <FontAwesomeIcon icon="fa-solid fa-times" />
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="to">
          <Form.Label column sm={2}>To:</Form.Label>
          <Col sm={9}>
            <Typeahead
              id="to-station"
              labelKey="label"
              options={stationOptions}
              onChange={(selected) => handleStationSelect(selected, 'to')}
              placeholder="Destination station"
              selected={stationOptions.filter(option => option.shortCode === formValues.to)}
            />
          </Col>
          <Col sm={1}>
            <Button variant="secondary" size="sm" onClick={() => handleClearStation('to')}>
              <FontAwesomeIcon icon="fa-solid fa-times" />
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="departure">
          <Form.Label column sm={2}>Earliest Departure:</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="datetime-local"
              name="departure"
              value={formValues.departure}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="arrival">
          <Form.Label column sm={2}>Latest Arrival:</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="datetime-local"
              name="arrival"
              value={formValues.arrival}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="goodsType">
          <Form.Label column sm={2}>Type of Goods:</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="goodsType"
              value={formValues.goodsType}
              onChange={handleInputChange}
              placeholder="Enter type of goods"
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="isDangerousGoods">
          <Form.Label column sm={2}>Dangerous Goods</Form.Label>
          <Col sm={10}>
            <Form.Check
              type="checkbox"
              label="Transportation has dangerous goods"
              name="isDangerousGoods"
              checked={formValues.isDangerousGoods}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        {formValues.isDangerousGoods && (
          <Form.Group as={Row} controlId="unNumber">
            <Form.Label column sm={2}>UN Number:</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="unNumber"
                value={formValues.unNumber}
                onChange={handleInputChange}
                placeholder="Enter UN number (if applicable)"
              />
            </Col>
          </Form.Group>
        )}

        <Button variant="primary" type="submit" className="mt-3">
          Submit Order
        </Button>
      </Form>
    </Container>
  );
};

export default OrderForm;  