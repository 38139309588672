import React, { useState, useRef, useEffect } from 'react';
import { auth } from "../Firebase";
import { Button, Form, Container, Row, Col, Modal, Alert } from 'react-bootstrap';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [resetPasswordSent, setResetPasswordSent] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const emailRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                navigate("/dashboard");
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/dashboard");
        } catch (error) {
            setError(error.message);
        }
    };

    const handleResetPasswordModalOpen = () => {
        setShowResetPasswordModal(true);
    };

    const handleResetPasswordModalClose = () => {
        setShowResetPasswordModal(false);
    };

    const handleResetPassword
        = async () => {
            try {
                const email = emailRef.current.value;
                await sendPasswordResetEmail(auth, email);
                setResetPasswordSent(true);
                setError(null);
                setShowResetPasswordModal(false);
            } catch (error) {
                setError(error.message);
            }
        };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6}>
                    <Form>
                        <h2>DLVR/Rail</h2>
                        {resetPasswordSent && (
                            <Alert variant="success">
                                Salasanan resetointiviesti lähti. Seuraapa siitä ohjeita.
                            </Alert>
                        )}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Käyttäjätunnus</Form.Label>
                            <Form.Control type="email" placeholder="Sähköposti" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Salasana</Form.Label>
                            <Form.Control type="password" placeholder="Salasana" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicSubmit">
                            <Button variant="primary" type="submit"
                                onClick={handleLogin}>
                                Kirjaudu
                            </Button>
                            <Button variant="link"
                                onClick={handleResetPasswordModalOpen}>
                                Salasana unohtunut
                            </Button>
                        </Form.Group>
                        {error && <p className="text-danger">{error}</p>}
                    </Form>
                </Col>
            </Row>
            <Modal show={showResetPasswordModal} onHide={handleResetPasswordModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Unohtunut salasana?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Sähköpostiosoite</Form.Label>
                            <Form.Control type="email" placeholder="Sähköposti" ref={emailRef} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleResetPasswordModalClose}>
                        Sulje
                    </Button>
                    <Button variant="primary" onClick={handleResetPassword}>
                        Lähetä palautusohjeet
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Login;