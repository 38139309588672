import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../Firebase";
import { signOut } from "firebase/auth"

const Navigation = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsLoggedIn(!!user);
            // Set isLoggedIn based on user existence
        });
        return () => unsubscribe();
    }, []); // Empty dependency array to run only once

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setIsLoggedIn(false); // Update state after logout
            navigate("/");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <>
            <Navbar expand="lg" className="bg-body-secondary">
                <Container>
                    {isLoggedIn ? (
                        <>
                            <Navbar.Brand as={Link} to="/dashboard">DLVR/Rail</Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbar-nav" />
                            <Navbar.Collapse id="navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link as={Link}
                                        to="/dashboard">Home</Nav.Link>
                                    <Nav.Link as={Link} to="/neworder">New order</Nav.Link>
                                    <Nav.Link as={Link} to="/adduser">Add user</Nav.Link>
                                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </>
                    ) : (
                        <Navbar.Brand as={Link} to="/">DLVR/Rail</Navbar.Brand>
                    )}
                </Container>
            </Navbar>
        </>
    );
};

export default Navigation;